import React, { Component } from 'react';
import './socials.scss';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faCheckSquare, faCoffee } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

library.add(fab, faCheckSquare, faCoffee)

class Socials extends Component {
  render() {
    return (
      <div id="socials-div" className="center">
        <a href="//github.com/thecodingaviator"><FontAwesomeIcon icon="fa-brands fa-github" /></a>
        <a href="//linkedin.com/in/hiparthparth"><FontAwesomeIcon icon="fa-brands fa-linkedin" /></a>
        <a href="//codepen.io/thecodingaviator"><FontAwesomeIcon icon="fa-brands fa-codepen" /></a>
      </div>
    )
  }
}

Socials.displayName = 'SocialsComponent';

export default Socials;