import React, { Component } from 'react';
import './link.scss';

class Link extends Component {
  render() {
    return <a href={this.props.href} className="styled-link" target={this.props.newPage?"blank":""}>{this.props.children}</a>;
  }
}

Link.displayName = 'Link';

export default Link;