import React, { Component } from 'react';
import './resume.scss';
import Link from '../link/link';

class Resume extends Component {
  render() {
    return (
      <div id="resume-div" className="center">
        <h2>You can find my resume <Link href="/resume" newPage>here</Link> <br /> Keep scrolling for more!</h2>
      </div>
    )
  }
}

Resume.displayName = 'ResumeComponent';

export default Resume;