import './App.scss';

import Landing from './components/landing/landing';
import Resume from './components/resume/resume';
import Socials from './components/socials/socials.js';
import Form from './components/form/form';
import Footer from './components/footer/footer';

function App() {
  return (
    <div className="App">
      <Landing />
      <Resume />
      <Socials />
      <Form />
      <Footer />
    </div>
  );
}

export default App;
