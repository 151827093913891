import React, { Component, lazy, Suspense } from 'react';
import './landing.scss';
import Link from '../link/link';

const LandingImage = lazy(() => import('./image'));

class Landing extends Component {
  render() {
    return (
      <div id="landing-div" className="left-right">
        <div id="landing-description" className="left">
          <div>
            <h1>
              Hi! 👋 I'm Parth
            </h1>
            <h2>
              <Link href={`#`}>Student</Link> and <Link href={`#`}>Web Developer</Link>
            </h2>
            <h2>
              Welcome to my website!
            </h2>
          </div>
        </div>
        <div id="landing-graphic" className="right">
          <Suspense fallback={<p>Quality content for here is arriving in 3, 2, 1......</p>}>
            <LandingImage />
          </Suspense>
        </div>
      </div>
    )
  }
}

Landing.displayName = 'LandingComponent';

export default Landing;