import React from "react";

import './footer.scss';

function Footer() {
  const year = new Date().getFullYear();

  return (
    <footer>
      <h2>
        &copy; Parth Parth {year}. <a href="mailto:hi@parthparth.com" className="styled-link">hi@parthparth.com</a>
      </h2>
    </footer>
  )
}

export default Footer;
