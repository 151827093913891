import React, { Component } from "react";
import Link from "../link/link";

import './form.scss';

class Form extends Component {
  constructor(props) {
    super(props);

    this.state = {
      emailUp: false,
      phoneUp: false
    }

    this.updateFormStyles = this.updateFormStyles.bind(this);
  }

  updateFormStyles(e) {
    this.setState({
      emailUp: document.querySelector('#email').value.length > 0,
      phoneUp: document.querySelector('#number').value.length > 0,
    });
  }

  render() {
    return (
      <div id="form-div" className="left-right">
        <div id="contact-heading" className="left">
          <div>
            <h1>Send me a message 🚀</h1>
            <br />
            <h2>✉️: <Link href="mailto:hi@parthparth.com">hi@parthparth.com</Link></h2>
            <br />
            <h2>...or a <Link href="//ko-fi.com/parthparth" newPage>cup of coffee ☕</Link></h2>
          </div>
        </div>
        <div id="contact-form" className="right">
          <div id="contact">
            <form name="contact" method="POST" action="/success" data-netlify="true" netlify-honeypot="namer" onChange={this.updateFormStyles}>
              <div className="group">
                <label className="no-show">Don’t fill this out if you're human: <input name="namer" /></label>
                <input type="hidden" name="form-name" value="contact" />
              </div>
              <div className="group">
                <input type="text" required={true} name="name" id="name" />
                <span className="highlight"></span>
                <span className="bar"></span>
                <label htmlFor="name">Name</label>
              </div>
              <div className="group">
                <input type="email" required={true} name="email" id="email" />
                <span className="highlight"></span>
                <span className="bar"></span>
                <label htmlFor="email" className={this.state.emailUp ? 'input-valid' : ''}>Email address</label>
              </div>
              <div className="group">
                <input type="tel" required={false} name="number" id="number" />
                <span className="highlight"></span>
                <span className="bar"></span>
                <label htmlFor="number" className={this.state.phoneUp ? 'input-valid' : ''}>Phone Number</label>
              </div>
              <div className="group">
                <textarea type="textarea" rows="5" required={true} name="message" id="message"></textarea>
                <span className="highlight"></span>
                <span className="bar"></span>
                <label htmlFor="message">Message</label>
              </div>
              <div className="btn-box">
                <button className="btn btn-submit" type="submit">Submit</button>
              </div>
            </form>
          </div>
        
        </div>
      </div>
    )
  };
}

Form.displayName = "Contact Form";

export default Form;